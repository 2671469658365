$(document).ready(function () {
  $(function () {
    if ($(window).width() < 768) {
      var diagnosisElement = $("#mob_diagnosis_slider");
      diagnosisElement.owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        autoWidth: true,
      });
      var treatmentElement = $("#treatment_slider");
      treatmentElement.owlCarousel({
        items: 1,
        loop: true,
        margin: 10,
        nav: true,
        navText: [
          '<i class="fa fa-chevron-left slick-arrow" style="display: block;"></i>',
          '<i class="fa fa-chevron-right slick-arrow" style="display: block;"></i>',
        ],
      });
    }
  });

  // START FOR MOBILE MENU HAMBURGER AND OVERLAY WITH FATNAV IN BOOK APPOINTMENT PAGE
  $(function () {
    $("#btn_mob_hamburger").click(function () {
      $("#fatnav").fadeIn();
    });
    $("#btn_close").click(function () {
      $("#fatnav").fadeOut();
    });
  });
  // END FOR MOBILE MENU HAMBURGER AND OVERLAY WITH FATNAV IN BOOK APPOINTMENT PAGE

  // START - ACCORDION IMAGE SET AND CONTENT
  $(function () {
    $("#accordionExample .accordion-item h2 button").click(function () {
      var imgsrc = $(this).attr("data-img");
      $("#image_set").attr("src", imgsrc);
    });
  });
  // END - ACCORDION IMAGE SET AND CONTENT

  // START INPUT VALID AND FOCUS 5
  $(".fillform_module :input").focusin(function () {
    $(this).parent().addClass("field_lbl_active");
  });
  $(".fillform_module :input").focusout(function () {
    if ($(this).val() === "") $(this).parent().removeClass("field_lbl_active");
  });
  // END INPUT VALID AND FOCUS 5

  // START FOR MOBILE DEVICE - SECTION JOURNEY
  $(function () {
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var slidesPerPage = 1; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1
      .owlCarousel({
        items: 1,
        slideSpeed: 3000,
        smartSpeed: 3000,
        lazyLoad: "ondemand",
        nav: true,
        autoplay: false,
        dots: true,
        loop: true,
        responsiveRefreshRate: 200,
        navText: [
          '<i class="fa fa-chevron-left"></i>',
          '<i class="fa fa-chevron-right"></i>',
        ],
      })
      .on("changed.owl.carousel", syncPosition);

    sync2
      .on("initialized.owl.carousel", function () {
        sync2.find(".owl-item").eq(0).addClass("current");
      })
      .owlCarousel({
        items: slidesPerPage,
        dots: false,
        nav: false,
        autoplay: false,
        smartSpeed: 3000,
        slideSpeed: 3000,
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate: 100,
      })
      .on("changed.owl.carousel", syncPosition2);

    function syncPosition(el) {
      //if you set loop to false, you have to restore this next line
      //var current = el.item.index;

      //if you disable loop you have to comment this block
      var count = el.item.count - 1;
      var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

      if (current < 0) {
        current = count;
      }
      if (current > count) {
        current = 0;
      }

      //end block

      sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
      var onscreen = sync2.find(".owl-item.active").length - 1;
      var start = sync2.find(".owl-item.active").first().index();
      var end = sync2.find(".owl-item.active").last().index();

      if (current > end) {
        sync2.data("owl.carousel").to(current, 100, true);
      }
      if (current < start) {
        sync2.data("owl.carousel").to(current - onscreen, 100, true);
      }
    }

    function syncPosition2(el) {
      if (syncedSecondary) {
        var number = el.item.index;
        sync1.data("owl.carousel").to(number, 100, true);
      }
    }

    sync2.on("click", ".owl-item", function (e) {
      e.preventDefault();
      var number = $(this).index();
      sync1.data("owl.carousel").to(number, 300, true);
    });
  });
  // END FOR MOBILE DEVICE - SECTION JOURNEY

  // START ACTIVE REDIRECT TO ANOTHER PAGE
  $(function () {
    var hash = window.location.hash;
    hash && $('ul.nav a[data-bs-target="' + hash + '"]').tab("show");
    hash &&
      $('.accordion-button[data-bs-target="' + hash + '"]').collapse({
        toggle: false,
      });

    // debugger;
  });
  // END ACTIVE REDIRECT TO ANOTHER PAGE

  // START FOR INNER PAGE SCROLL
  $(function () {
    var sPageURL = window.location.href.split("#")[1];
    sPageURL = sPageURL || "";
    $('a[href="#' + sPageURL + '"]').addClass("active");
    if (sPageURL != "") {
      $("html,body").animate({
        scrollTop: $("#" + sPageURL).offset().top - 120,
      });
    }
  });
  // END FOR INNER PAGE SCROLL

  // START FOR SECTION SMOOTH SCROLLING
  // $(function(){
  // 	$('.list_navsection li a[href^="#"]').on('click', function (e) {
  //            $('.list_navsection li a').each(function () {
  //                $(this).removeClass('active');
  //            })
  //            $(this).addClass('active');
  //            var target = this.hash;
  //            $('html,body').animate({scrollTop: $(target).offset().top-120});
  //            e.preventDefault();
  //        });
  //    });
  // END FOR SECTION SMOOTH SCROLLING

  // START FOR VIDEO IFRAME YOUTUBE IN MODAL
  $(function () {
    $(".modal").on("hidden.bs.modal", function (e) {
      $(".modal .iframe_video").attr(
        "src",
        $(".modal .iframe_video").attr("src")
      );
    });
  });
  // END FOR VIDEO IFRAME YOUTUBE IN MODAL

  // START FOR BANNER SLIDER
  /*
	$(function(){
		// Init slick slider + animation
		$('#desk_banner_slider').slick({
		  autoplay: true,
		  autoplaySpeed: 800,
		  speed: 800,
		  lazyLoad: 'progressive',
		  arrows: false,
		  dots: true,
		  nav:false,
		}).slickAnimation();

		$('#mob_banner_slider').slick({
		  autoplay: true,
		  autoplaySpeed: 1500,
		  speed: 1500,
		  lazyLoad: 'progressive',
		  arrows: false,
		  dots: false,
		  nav:false,
		  fade: true,
		}).slickAnimation();
	});
	*/
  // END FOR BANNER SLIDER

  // START FOR FEEDBACK FROM PATIENTS - SLICK CAROUSEL
  /*
	$('#feedback_slider').slick({
	  infinite: true,
	  slidesToShow: 3, // Shows a three slides at a time
	  slidesToScroll:1, // When you click an arrow, it scrolls 1 slide at a time
	  arrows: false, // Adds arrows to sides of slider
	  dots: true, // Adds the dots on the bottom
	  autoplay:true,
		  autoplaySpeed:1500,
		  speed: 1500,
	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 3,
	        infinite: true,
	        dots: true
	      }
	    },
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	   ]
	});
	*/
  // END FOR FEEDBACK FROM PATIENTS - SLICK CAROUSEL

  // START FOR MENU HAMBURGER AND TOPNAV
  $(function () {
    $(".btn_hamburger").click(function () {
      $(this).toggleClass("active");
      $(".topnav .list_nav").slideToggle();
    });
  });
  // END FOR MENU HAMBURGER AND TOPNAV

  // START FOR DATE FULLYEAR
  $(function () {
    var d = new Date().getFullYear();
    document.getElementById("txt_fullyear").innerHTML = d;
  });
  // END FOR DATE FULLYEAR

  // START FOR FOOTER FIXED BOTTOM
  $(function () {
    var contentHeight = jQuery(window).height();
    var footerHeight = jQuery("footer").height();
    var footerTop = jQuery("footer").position().top + footerHeight;
    if (footerTop < contentHeight) {
      //  $('.index-page').css('min-height', (contentHeight - 520) + 'px');
      $("footer").css("margin-top", contentHeight - footerTop + "px");
    }
  });
  // END FOR FOOTER FIXED BOTTOM

  // START FOR SCROLL TOP AND BOTTOM ANIMATED
  /*
	$(function(){
		WOW.prototype.addBox = function (element) {
	    this.boxes.push(element);
	  };

	  // Init WOW.js and get instance
	  var wow = new WOW();
	  wow.init();

	  // Attach scrollSpy to .wow elements for detect view exit events,
	  // then reset elements and add again for animation /*
	  $('.wow').on('scrollSpy:exit', function () {
	    $(this).css({
	      'visibility': 'hidden',
	      'animation-name': 'none'
	    }).removeClass('animated');
	    wow.addBox(this);
	  }).scrollSpy();
	});
	*/
  // END FOR SCROLL TOP AND BOTTOM ANIMATED

  // START FOR SCROLL TOP
  $(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $(".backtop").fadeIn();
      } else {
        $(".backtop").fadeOut();
      }
    });

    $(".backtop").click(function () {
      $("html,body").animate({ scrollTop: 1 }, 1000);
    });
  });
  // END FOR SCROLL TOP
});
